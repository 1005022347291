<template>
  <transition name="slide-in">
    <div v-show="showFlag" class="msg-login">
      <h :iscancel="true" @cancel="hide">
        <span class="name">{{ article.title }}</span>
      </h>
      <div class="article" v-text="article.text" />
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import { mixins } from "@/assets/mixin/login";
import { getProtocol } from "@/api/user";
export default {
  mixins: [mixins],
  components: {
    h
  },
  data() {
    return {
      showFlag: false,
      article: {}
    };
  },
  created() {
    this._getProtocol();
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    },
    _getProtocol() {
      getProtocol().then(res => {
        this.article = res.object;
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
@import "~assets/stylus/login.styl";
.msg-login
  position fixed
  top 36px
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .article
    padding 15px
    font-size 14px
</style>

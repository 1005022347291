<template>
  <transition name="slide-in">
    <div v-show="showFlag" class="msg-login">
      <h :iscancel="true" @cancel="hide">
        <span class="name">注册</span>
      </h>
      <div class="form">
        <div class="input-wrap border-bottom-1px country-code">
          <div class="label">国家与地区</div>
          <div class="select" @click="showAliasPicker">
            <span
              v-text="
                `
                ${defaultCountry.chineseShortName}(${defaultCountry.countryCode})
                `
              "
            />
            <i class="cubeic-arrow" />
          </div>
        </div>
        <div class="input-wrap border-bottom-1px phone-wrap">
          <div class="input">
            <cube-input
              v-model="loginParams.phone"
              placeholder="请输入手机号"
            />
          </div>
          <div class="btn">
            <cube-button
              :disabled="codeTime > 0"
              @click="getCode"
              v-text="codeTime <= 0 ? '获取验证码' : codeTime + 's'"
            />
          </div>
        </div>
        <div class="input-wrap border-bottom-1px">
          <cube-input v-model="loginParams.code" placeholder="请输入验证码" />
        </div>
        <div class="input-wrap border-bottom-1px">
          <cube-input v-model="loginParams.nickName" placeholder="请输入昵称" />
        </div>
        <div class="input-wrap border-bottom-1px">
          <cube-input
            v-model="loginParams.password"
            type="password"
            :eye="{ open: false, reverse: false }"
            placeholder="设置密码(6-30位且不包含空格)"
          />
        </div>
        <div class="input-wrap border-bottom-1px">
          <cube-input
            v-model="loginParams.repassword"
            type="password"
            :eye="{ open: false, reverse: false }"
            placeholder="确认密码"
          />
        </div>
        <div class="input-wrap border-bottom-1px">
          <cube-input
            v-model="loginParams.invtationCode"
            placeholder="请输入邀请码（选填）"
          />
        </div>
        <p class="invtationCode-tips">*输入邀请码可以获得丰厚奖励</p>
        <div class="btn-wrap login">
          <cube-button @click="toRegisterFun">注册</cube-button>
        </div>
        <div class="protocol">
          <cube-checkbox class="with-click" v-model="checked">
            我已阅读并同意
          </cube-checkbox>
          <a @click.stop="showArticle">
            《服务和隐私条例》
          </a>
        </div>
      </div>
      <pro-article ref="article" />
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import proArticle from "@/components/login/article";
import { mixins } from "@/assets/mixin/login";
import { getCodeMsg, registerForPhone } from "@/api/user";
export default {
  mixins: [mixins],
  components: {
    h,
    proArticle
  },
  data() {
    return {
      showFlag: false,
      checked: false,
      codeTime: 0
    };
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    },
    showArticle() {
      this.$refs.article.show();
    },
    getCode() {
      if (!this.loginParams.phone) {
        this.showToast("warn", "请输入手机号码");
        return;
      }
      if (this.codeTime > 0) {
        return;
      }
      getCodeMsg(
        1,
        this.loginParams.phone,
        this.defaultCountry.countryCode
      ).then(res => {
        if (!res) {
          return;
        }
        this.codeTime = 60;
        const timer = setInterval(() => {
          this.codeTime--;
          if (this.codeTime <= 0) {
            clearInterval(timer);
          }
        }, 1000);
      });
    },
    toRegisterFun() {
      if (!this.loginParams.phone) {
        this.showToast("warn", "请输入手机号码");
        return;
      }
      if (!this.loginParams.code) {
        this.showToast("warn", "请输入验证码");
        return;
      }
      if (!this.loginParams.nickName) {
        this.showToast("warn", "请输入昵称");
        return;
      }
      if (!this.loginParams.password) {
        this.showToast("warn", "请输入密码");
        return;
      }
      if (this.loginParams.password !== this.loginParams.repassword) {
        this.showToast("warn", "两次输入的密码不一致，请重新输入");
        return;
      }
      const value = this.loginParams.nickName;
      const chinese = /[\u4e00-\u9fa5]/gm;
      const chineseLen = value.match(chinese) ? value.match(chinese).length : 0;
      const allLen =
        value.length === chineseLen
          ? chineseLen * 2
          : value.length - chineseLen + chineseLen * 2;
      const minLen = 4;
      const maxLen = 20;
      if (allLen < minLen || allLen > maxLen) {
        this.showToast("warn", "昵称长度应为4-20位字符(汉字占两位字符)");
        return;
      }
      if (!this.checked) {
        this.showToast("correct", "请阅读并同意《服务及隐私条例》");
        return;
      }
      const params = {
        phone: this.loginParams.phone,
        code: this.loginParams.code,
        countryCode: this.defaultCountry.countryCode,
        nickName: this.loginParams.nickName,
        password: this.loginParams.password,
        invtationCode: this.loginParams.invtationCode
      };
      registerForPhone(params).then(res => {
        if (!res) {
          return;
        }
        this.showToast("correct", "注册成功，将为您自动登录");
        this.loginAfter(res.object);
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
@import "~assets/stylus/login.styl";
.msg-login
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .form
    padding 30px 0 0
    .login
      margin-top 25px
    .protocol
      text-align center
      .cube-checkbox
        display inline-block
        padding 0
      a
        position relative
        top -1px
        display inline-block
        vertical-align middle
        line-height 1.5
        color #2a9bfb
    .invtationCode-tips
      padding-left 10px
      color #727171
</style>

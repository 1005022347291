<template>
  <transition name="slide">
    <div class="login-container">
      <img src="./img/logo.png" class="logo" width="173" />
      <div class="form">
        <div class="input-wrap country-code">
          <div class="label">国家与地区</div>
          <div class="select" @click="showAliasPicker">
            <span
              v-text="
                `
                ${defaultCountry.chineseShortName}(${defaultCountry.countryCode})
                `
              "
            />
            <i class="cubeic-arrow" />
          </div>
        </div>
        <div class="input-wrap">
          <cube-input v-model="loginParams.phone" placeholder="请输入手机号" />
        </div>
        <div class="input-wrap">
          <cube-input
            v-model="loginParams.password"
            type="password"
            :eye="{ open: false, reverse: false }"
            placeholder="请输入密码"
            @keyup.enter.native="_loginPassword"
          />
        </div>
        <div class="other-tool">
          <div class="msg-login">
            <span @click="showMsglogin">验证码登录</span>
          </div>
          <div class="forget-password">
            <span @click="showResetpassword">忘记密码？</span>
          </div>
        </div>
        <div class="btn-wrap login">
          <cube-button :disabled="loginBtn" @click="_loginPassword">
            登录
          </cube-button>
        </div>
        <div class="btn-wrap register">
          <cube-button @click="showRegister">注册</cube-button>
        </div>
      </div>
      <msg-login ref="msg" />
      <reset-password ref="password" @hide="relogin" />
      <register ref="register" />
    </div>
  </transition>
</template>
<script>
import msgLogin from "@/components/login/msg-login";
import resetPassword from "@/components/login/reset-password";
import register from "@/components/login/register";
import { mixins } from "@/assets/mixin/login";
import { loginPassword } from "@/api/user";
export default {
  mixins: [mixins],
  components: {
    msgLogin,
    resetPassword,
    register
  },
  created() {
    this.getCountryList();
  },
  methods: {
    showMsglogin() {
      this.$refs.msg.show();
    },
    showResetpassword() {
      this.$refs.password.show();
    },
    showRegister() {
      this.$refs.register.show();
    },
    _loginPassword() {
      if (!this.loginParams.phone) {
        this.showToast("warn", "请输入登录手机号");
        return;
      }
      if (!this.loginParams.password) {
        this.showToast("warn", "请输入登录密码");
        return;
      }
      this.loginBtn = true;
      const params = {
        phone: this.loginParams.phone,
        password: this.loginParams.password,
        countryCode: this.defaultCountry.countryCode
      };
      loginPassword(params)
        .then(res => {
          if (!res) {
            return;
          }
          this.loginAfter(res.object);
        })
        .finally(() => {
          this.loginBtn = false;
        });
    },
    relogin() {
      this.$refs.password.hide();
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
@import "~assets/stylus/login.styl";
.login-container
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 10
  .logo
    display block
    margin 74px auto
</style>

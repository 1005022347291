<template>
  <transition name="slide-in">
    <div v-show="showFlag" class="msg-login">
      <h :iscancel="true" @cancel="hide">
        <span class="name">验证码登录</span>
      </h>
      <div class="form">
        <div class="input-wrap border-bottom-1px country-code">
          <div class="label">国家与地区</div>
          <div class="select" @click="showAliasPicker">
            <span
              v-text="
                `
                ${defaultCountry.chineseShortName}(${defaultCountry.countryCode})
                `
              "
            />
            <i class="cubeic-arrow" />
          </div>
        </div>
        <div class="input-wrap border-bottom-1px phone-wrap">
          <div class="input">
            <cube-input
              v-model="loginParams.phone"
              placeholder="请输入手机号"
            />
          </div>
          <div class="btn">
            <cube-button
              :disabled="loginCodeTime > 0"
              v-text="loginCodeTime <= 0 ? '获取验证码' : loginCodeTime + 's'"
              @click="getLoginCode"
            />
          </div>
        </div>
        <div class="input-wrap border-bottom-1px">
          <cube-input v-model="loginParams.code" placeholder="请输入验证码" />
        </div>
        <div class="btn-wrap login">
          <cube-button :disabled="loginBtn" @click="_loginCode">
            登录
          </cube-button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import { mixins } from "@/assets/mixin/login";
import { loginCode } from "@/api/user";
import { getCodeMsg } from "@/api/user";
export default {
  mixins: [mixins],
  components: {
    h
  },
  data() {
    return {
      showFlag: false,
      loginCodeTime: 0
    };
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    },
    getLoginCode() {
      if (!this.loginParams.phone) {
        this.showToast("warn", "请输入手机号码");
        return;
      }
      if (this.loginCodeTime > 0) {
        return;
      }
      getCodeMsg(
        2,
        this.loginParams.phone,
        this.defaultCountry.countryCode
      ).then(res => {
        if (!res) {
          return;
        }
        this.loginCodeTime = 60;
        this.loginCodeTimer = setInterval(() => {
          this.loginCodeTime--;
          if (this.loginCodeTime <= 0) {
            clearInterval(this.loginCodeTimer);
          }
        }, 1000);
      });
    },
    _loginCode() {
      if (!this.loginParams.phone) {
        this.showToast("warn", "请输入手机号码");
        return;
      }
      if (!this.loginParams.code) {
        this.showToast("warn", "请输入验证码");
        return;
      }
      this.loginBtn = true;
      const params = {
        phone: this.loginParams.phone,
        code: this.loginParams.code,
        countryCode: this.defaultCountry.countryCode
      };
      loginCode(params)
        .then(res => {
          if (!res) {
            return;
          }
          this.loginAfter(res.object);
        })
        .finally(() => {
          this.loginBtn = false;
        });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
@import "~assets/stylus/login.styl";
.msg-login
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .form
    padding 30px 0 0
    .login
      margin-top 25px
</style>
